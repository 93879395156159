import React, {Component} from 'react';
import {Link} from "react-router-dom";

class Footer extends Component {
    render() {
        const { routes, pageConfig } = this.props;
        if (pageConfig !== null)
        {
            return (
                <footer>
                    <div className="container-fluid bg-green no-mobile">
                        <div className="container py-4">
                            <div className="row">
                                <div className="col-sm-3 pt-1">
                                    <a className="navbar-brand" href="/">{pageConfig.companyLogo && <img src={"https://www.logicalsites.com" + pageConfig.companyLogo} alt={pageConfig.companyName} />} {pageConfig.companyName}</a>
                                </div>
                                <div className="col-sm-9 px-0">
                                    <nav className="navbar navbar-expand-lg">
                                        <ul className="navbar-nav ml-auto">
                                            {routes.map((route, index) => {
                                                if (route.active === "1") { return (<li key={index} className="nav-item"><Link className="nav-link text-white" title={"Navigation link to " + route.name} onClick={this.toggleNavbar} to={route.linkUrl}>{route.name}</Link></li>); }
                                                else { return(null); }
                                            })}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-dark text-white mt-0 container-fluid">
                        <div className="container">
                            <div className="row">
                                <div className="py-3 small col-12">
                                    <p className="p-0 m-0" dangerouslySetInnerHTML={{__html: "&copy; " + pageConfig.copyright}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            )
        }
        else
        {
            return (<div>&nbsp;</div>);
        }
    }
}

export default Footer;