import React, {Component, Fragment} from 'react';
import {Helmet} from "react-helmet";

class Error404 extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Error 404: Page Not Found</title>
                    <meta name="description" content="Error 404: Page Not Found, The page you are trying to reach does not exist" />
                    <meta name="theme-color" content="#008f68" />
                </Helmet>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="text-center my-5">Error 404: Page Not Found</h1>
                            <p className="text-center">The page you are trying to reach does not exist</p>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default Error404
