import React, {Component, Fragment} from 'react';
import {Helmet} from "react-helmet";
import ReactGA from "react-ga";
import axios from "axios";
import {apiKey, apiURL} from "../../config";
import Index from "../Widgets/FeaturedBoxes";
import RotatingHeader from 'react-image-gallery';
import AdSense from 'react-adsense';
import {Parallax} from "react-parallax";
import CMATIONNewsWidget from "../Widgets/News";
import CMATIONWeatherWidget from "../Widgets/Weather";

class ContentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: [],
            rotatingImages: [],
            widgets: {},
            timestamp: 0
        };
    };
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState === this.state) {
            this.getContent();
        }
    }
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        this.getContent();
    }
    getContent = () => {
        let path = '';
        if (typeof(this.props.match) === 'undefined') {
            path = this.props.path;
        } else {
            path = this.props.match.path;
        }

        axios.get(apiURL + 'getContentPage', {params: {apiKey: apiKey, path: path}})
            .then( (response) => {
                if (typeof(response.data.page) !== 'undefined') {
                    this.setState({
                        "content": response.data.page,
                        "rotatingImages": response.data.rotatingImages,
                        "widgets": response.data.widgets
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    render() {
        const { content, rotatingImages, widgets } = this.state;
        const { pageConfig } = this.props;

        if (typeof(content.loaded) === 'undefined' || content.id === '') {
            return(null);
        } else {

            return (
                <Fragment>
                    <Helmet>
                        <title>{content.metaTitle}</title>
                        <meta name="description" content={content.metaDescription} />
                        <meta name="robots" content={content.metaRobots} />
                        <meta name="googlebot" content={content.metaGoogleBot} />
                        <meta name="keywords" content={content.metaKeywords} />
                        <meta name="theme-color" content="#008f68" />
                    </Helmet>

                    {typeof(widgets.rotatingHeaderImage) !== 'undefined' && <Fragment>
                        {rotatingImages && <RotatingHeader
                            items={rotatingImages}
                            showBullets={false}
                            showPlayButton={false}
                            showThumbnails={false}
                            showFullscreenButton={false}
                            autoPlay={true}
                            slideInterval={4000}
                            slideDuration={500}
                            additionalClass="headerImage no-mobile"
                        />}
                    </Fragment>}
                    {typeof(widgets.rotatingHeaderImage) === 'undefined' && <Parallax
                        bgImage={pageConfig.pageHeaderBackground}
                        bgImageAlt={"Header Image"}
                        strength={700}
                        blur={{ min: -15, max: 15 }}
                    >
                        <div className="container my-4 py-4">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="text-center my-5 text-uppercase">{content.title}</h1>
                                </div>
                            </div>
                        </div>
                    </Parallax>}

                    {typeof(widgets.featuredBoxes) !== 'undefined' && <Index
                        list={widgets.featuredBoxes}
                    />}

                    <div className="container-fluid bg-light py-5 mx-0 px-0">
                        <div className="container rounded bg-white py-5">
                            <div className="row">
                                <div className="col-sm-12 my-4">
                                    <AdSense.Google
                                        client='ca-pub-2395683201327556'
                                        slot='3846590389'
                                        style={{ display: 'block' }}
                                        format='auto'
                                        responsive='true'
                                        layoutKey='-gw-1+2a-9x+5c'
                                    />
                                </div>
                                <div className="col-sm-12 my-5">
                                    {typeof(widgets.rotatingHeaderImage) !== 'undefined' && <h1 className="text-center">{content.title}</h1>}
                                    <div dangerouslySetInnerHTML={{__html: content.content}} />
                                </div>
                            </div>
                        </div>

                        {typeof(widgets.weather) !== 'undefined' && <CMATIONWeatherWidget
                            data={widgets.weather}
                        />}

                        <div className="container rounded bg-white py-5">
                            <div className="row">
                                <div className="col-sm-12 my-4">
                                    {typeof(widgets.news) !== 'undefined' && <CMATIONNewsWidget
                                        data={widgets.news}
                                    />}

                                </div>
                                <div className="col-sm-12 my-4">
                                    <AdSense.Google
                                        client='ca-pub-2395683201327556'
                                        slot='3846590389'
                                        style={{ display: 'block' }}
                                        format='auto'
                                        responsive='true'
                                        layoutKey='-gw-1+2a-9x+5c'
                                    />
                                </div>

                            </div>
                        </div>
                    </div>

                </Fragment>
            );
        }
    }
}
/*

                    <AdditionalContent
                        additionalContent={content.additionalContent.split(";")}
                        additionalContentList={content.additionalContentList}
                        featuredBoxesList={content.featuredBoxesList}
                        pageConfig={pageConfig}
                    />
 */
export default ContentPage
