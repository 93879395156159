import {
    GET_DATA_CATEGORIES,
    GET_PUBLISH_SITE,
    GET_TABLE_DATA
} from "../actions/types";
const initialState = {
    categories: {},
    tableData: {},
    pageResults: {}
};
export default function(state = initialState, action) {
    switch(action.type) {

        case GET_TABLE_DATA:
            return {
                ...state,
                tableData: action.payload
            };

        case GET_DATA_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            };

        case GET_PUBLISH_SITE:
            return {
                ...state,
                pageResults: action.payload
            };

        default:
            return state;

    }
}
