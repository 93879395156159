import React, {Component, Fragment} from 'react';

class Listings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listings: {}
        };
    };

    render() {
        const { listings } = this.props;
        return (<div className="container">
            <div className="row">
                <div className="col-12">
                    {typeof(listings) !== 'undefined' && <Fragment>
                        {listings.map((listing, index) => {
                            return (
                                <div className="row py-4 border-top" key={index}>
                                    <div className="col-12 col-sm-4">
                                        <a href={listing.externalLinkURL} title={listing.businessName} target="_blank"><img src={"/assets/images/" + listing.listingPhoto} alt={listing.businessName} className="img-fluid" /></a>
                                    </div>
                                    <div className="col">
                                        <h4 className="p-0 m-0"><a href={listing.externalLinkURL} className="text-success" target="_blank"><b>{listing.businessName}</b></a></h4>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-4 small py-2">
                                                    <p>toll-free: {listing.tollFree}<br/>
                                                    phone: {listing.phone}</p>
                                                    <p>{listing.address1}, {listing.city}, {listing.state} {listing.zip}</p>
                                                </div>
                                                <div className="col-8 small py-2">
                                                    {listing.listingDescription}
                                                </div>
                                            </div>
                                        </div>
                                        <a href={listing.externalLinkURL} title={listing.businessName} target="_blank" className="btn btn-sm btn-success small">Visit Website</a>
                                    </div>
                                </div>
                            );
                        })}
                    </Fragment>}
                </div>
            </div>
        </div>);
    }
}

export default Listings