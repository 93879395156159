import React, {Component} from 'react';
import axios from "axios";
import WeatherIcon from 'react-icons-weather';
import {Parallax} from "react-parallax";
import { widgetURL } from "../../../config";
import weatherImage from "../../../assets/images/weather.jpg";

class CMATIONWeatherWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            weatherData: {},
            localWeather: 0
        };
    };
    componentDidMount() {
        this.getWeather();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("componentDidUpdate");
        if (prevState === this.state) {
            console.log("componentDidUpdate this")
            // this.getWeather();
        }
    }
    getWeather = () => {
        axios.get(widgetURL + "getWeather")
            .then((response) => {
                console.log("getWeather", response.data);
                if (typeof (response.data) !== 'undefined') {

                    this.setState({ weatherData: response.data.weatherData});
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    render() {
        let doy = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
        const { weatherData } = this.state;
        if (typeof(weatherData.currently) === 'undefined') {
            return(null);
        } else {

            let currentWDate = new Date(0);
            currentWDate.setUTCSeconds(weatherData.currently.time);

            return (
                <Parallax
                    bgImage={weatherImage}
                    bgImageAlt="Weather - View of the leaves changing in Arkansas"
                    strength={700}
                    blur={{ min: -15, max: 15 }}
                >
                    <div className="container-fluid py-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h5 className="text-center h2">current weather</h5>
                                </div>
                            </div>
                        </div>
                        <div className="container pt-0">
                            <div className="row">
                                <div className="col-6 ml-auto mr-auto rounded bg-white px-5">
                                    <table className="table table-borderless py-0">
                                        <tbody>
                                            <tr>
                                                <td><br />
                                                    <h1><WeatherIcon name="darksky" iconId={weatherData.currently.icon} flip="horizontal" rotate="90" /> {parseInt(weatherData.currently.temperature)}&deg;F</h1>
                                                </td>
                                                <td>
                                                    <small>
                                                        Holiday Island, AR<br />
                                                        Currently: {weatherData.currently.summary}<br />
                                                    </small>
                                                </td>
                                                <td>
                                                    <small>
                                                        Precipitation: {weatherData.currently.precipProbability}%<br />
                                                        Humidity: {parseInt(weatherData.currently.humidity * 100)}%<br />
                                                        Wind: {parseInt(weatherData.currently.windSpeed)} mph
                                                    </small>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="container pt-5">
                            <div className="row">
                                <div className="col-12">
                                    <h5 className="text-center h2 p-0 m-0">next 5 days</h5>
                                </div>
                            </div>
                        </div>

                        <div className="container pt-0">
                            <div className="row">
                                <div className="col-12 text-center px-5">
                                    <table className="table table-borderless">
                                        <tbody>
                                            <tr>
                                                {weatherData.daily.data.map((day, index) => {
                                                    if (index < 5) {
                                                        let currentDate = new Date(0);
                                                        currentDate.setUTCSeconds(day.time);
                                                        return (<td key={index}>
                                                            <div className="rounded bg-white text-center py-4 px-4">
                                                                <h5 className="m-0 p-0 h3">{doy[currentDate.getDay()]}</h5>
                                                                <small>{currentDate.getMonth()} / {currentDate.getDate()}</small><br/>
                                                                <h5 className="h1 py-4"><WeatherIcon name="darksky" iconId={day.icon} flip="horizontal" rotate="90"/></h5>
                                                                {parseInt(day.apparentTemperatureHigh)}&deg; / {parseInt(day.apparentTemperatureLow)}&deg; <br/>
                                                            </div>
                                                        </td>);
                                                    }
                                                    return (null);
                                                })}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>


                    </div>
                </Parallax>
            );
        }
    }
}

export default CMATIONWeatherWidget