import React from 'react';
import classnames from 'classnames';

const FormInputText = ({name, value, label, placeholder, defaultValue, type, valueOptions, helpText, onChange, error}) => {
    return (
        <div className="form-group row border-bottom pb-2">
            <label htmlFor={name} className="col-sm-4 m-0 pt-2 pb-2">{label}</label>
            <div className="col-sm-8">
                <input
                    id={name}
                    name={name}
                    type={type}
                    value={value}
                    className={classnames('form-control form-control-lg', { 'is-invalid': error})}
                    onChange={onChange}
                    aria-describedby={name+"Help"}
                    placeholder={placeholder}
                />
            </div>
            {helpText && <small id={name + "Help"} className="col-12 form-text text-muted">{helpText}</small>}
            {error && <div className="col-12 alert alert-danger"><i className="fas fa-arrow-alt-circle-up"/> {error}</div>}
        </div>
    )
};

export default FormInputText