import React, {Component, Fragment} from 'react';
class Index extends Component {
    render() {
        const { title, list } = this.props;
        return (
            <Fragment>
                <div className="container-fluid m-0 px-0" style={{backgroundColor: '#dfdfdf'}}>
                    {title &&
                    <div className="container my-4 py-4">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="h1 py-4 my-4 text-center">{title}</h2>
                            </div>
                        </div>
                    </div>}
                    <div className="container p-4 my-0">
                        <div className="row">
                            {typeof(list) !== 'undefined' && <Fragment>
                                {list.map((content, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <div className="col-sm-3 p-4 text-center">
                                                <a href={content.linkUrl}>
                                                <div className="p-4 bg-white text-center" style={{textTransform: 'lowercase', backgroundColor: '#eaeaea', color: '#000000'}}>
                                                    <i className={content.icon + " py-4 text-black"} style={{fontSize: '60px', color: '#000000'}}/><br />
                                                    {content.name}
                                                </div>
                                                </a>
                                            </div>
                                        </Fragment>
                                    );
                                })}
                            </Fragment>}
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Index