import React, { Component} from 'react';
import classnames from 'classnames';
import Uploading from "../components/layout/Uploading";

class FormInputFile extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            uploading: false,
            uploadErrorMessage: ''
        };
        this.onUpload = this.onUpload.bind(this);
        this.onDeleteFile = this.onDeleteFile.bind(this);
    };
    onUpload = (uploadFiles) => {
        const files = Array.from(uploadFiles);
        const API_URL = window.apiUrl;
        this.setState({ uploading: true, uploadErrorMessage: '' });
        const formData = new FormData();

        let uploadFieldName = this.props.name;
        formData.append('name', this.props.name);
        formData.append('tableName', this.props.uploadFolder);
        files.forEach((file, i) => {
            formData.append(i, file);
        });

        fetch(`${API_URL}/file-upload`, {method: 'POST', body: formData})
            .then(res => res.json())
            .then(images => {
                if (images.uploadStatus === 0) {
                    this.setState({uploading: false, uploadErrorMessage: images.uploadMessage});
                } else if (images.newFileName !== '') {
                    this.setState({uploading: false});
                    this.props.onValueChange(uploadFieldName, images.newFileName);
                }
            })
    };
    onDeleteFile = (name) => {
        this.props.onValueChange(this.props.name, '');
    };
    render() {
        const { name, value, label, placeholder, helpText, error } = this.props;
        const { uploading, uploadErrorMessage } = this.state;
        return (<div className="form-group row border-bottom pb-2">
            <label className="col-12" htmlFor={name}>{label}</label>
            {uploadErrorMessage !== '' && <div className="alert alert-danger col-12">{uploadErrorMessage}</div>}
            {value && <div className="col-12 py-2"> <b>{value}</b> <button className="btn btn-danger" onClick={() => this.onDeleteFile(name)}>Delete File</button></div> }
            <div className="col-12">
                {uploading === true && <Uploading />}
                {uploading === false && value === '' &&
                    <input
                        id={name}
                        name={name}
                        type="file"
                        className={classnames('form-control', { 'is-invalid': error})}
                        onChange={(e) => this.onUpload(e.target.files)}
                        aria-describedby={name+"Help"}
                        placeholder={placeholder}
                    />}
            </div>
            {helpText && <small id={name + "Help"} className="col-12 form-text text-muted">{helpText}</small>}
            {error && <div className="col-12 alert alert-danger"><i className="fas fa-arrow-alt-circle-up" /> {error}</div>}
        </div>)
    }
}

export default FormInputFile