import React, { Component } from 'react';
import "react-toggle/style.css"
import Toggle from 'react-toggle'

class FormInputRadio extends Component {
    render() {
        const { name, label, helpText, onChangeToggle, error } = this.props;
        let defaultValue = this.props.defaultValue;
        let value = this.props.value;

        if (typeof(value) !== 'undefined')
        {
            defaultValue = value;
        }

        let defaultChecked = (defaultValue === 'true' || defaultValue === true || defaultValue === 1 || defaultValue === '1');
        return (
            <div className="form-group row border-bottom pb-2">
                <label htmlFor={name} className="col-sm-4">{label}</label>
                <div className="col-sm-8">
                    <Toggle
                        name={name}
                        defaultChecked={defaultChecked}
                        icons={false}
                        onChange={onChangeToggle}
                    />
                </div>
                {helpText && <small id={name+"Help"} className="col-12 form-text text-muted">{helpText}</small>}
                {error && <div className="col-12 alert alert-danger"><i className="fas fa-arrow-alt-circle-up" /> {error}</div> }
            </div>
        )
    }
}

export default FormInputRadio
